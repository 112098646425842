import * as React from "react";
import { header, headerCol } from "./Account.module.scss";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

export const StyledContainer = (props: { children: React.ReactNode }) => {
  return <Container fluid="sm">{props.children}</Container>;
};
export const ProfileHeader = () => {
  return (
    <>
      <div className={header}>
        <Container fluid="sm">
          <Row className={`px-1 px-lg-0`}>
            <Col className={headerCol}>
              <svg width={115} height={64}>
                <use xlinkHref="/svg/travel-pass-logo-new.svg#StaticImage">
                  {" "}
                </use>
              </svg>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
